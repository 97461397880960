import toastr from 'toastr';
import Unimplemented from './unimplemented';

export default class ListItems {

    constructor($view) {
        this.view = $view;
        this.itemNames = 'items';
        this.init();
    }

    init() {
        let self = this;

        // On map le contexte des functions sur l'instance
        self.create = self.create.bind(self);
        self.update = self.update.bind(self);
        self.delete = self.delete.bind(self);
        self.import = self.import.bind(self);
        self.export = self.export.bind(self);

        self._consolidateList = self._consolidateList.bind(self);
        self._toggleEmptyLine = self._toggleEmptyLine.bind(self);
        self._triggerUpload = self._triggerUpload.bind(self);
        
        self.itemNames = self.view.data('item-names');

        self.view.on('click', '.badge-info', (e) => self.create(e));
        self.view.on('click', '.badge-success', (e) => self.update(e));
        self.view.on('click', '.badge-danger', (e) => self.delete(e));

        self.view.on('click', '.upload > label', (e) => self._triggerUpload('.upload > input'));
        self.view.on('click', '#import-toolbar', (e) => self._triggerUpload('.upload > input'));
        

        self.view.on('change', '.import', (e) => self.import(e));

        self.view.on('click', '.export', (e) => self.export(e));
        self.view.on('click', '#export-toolbar', (e) => self.export(e));

        self._toggleEmptyLine();
    }

    create(e) {
        e.stopPropagation();

        let self = this;

        let url = self.view.data('new-url');
        if (isEmpty(url)) {
            console.warn("ListItems : couldn't found new-url for item '" + xpath(self.view[0]) + "'");
            return;
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: {
                id: self.view.data('id'),
                position: self.view.find('.list-item').length
            },
            success: function (data) {
                self.view.find('.list-items').append(data);
                self._toggleEmptyLine();
            },
            error: function (xhr, ajaxOptions, thrownError) {

            }
        });
    }

    update(e) {
        e.stopPropagation();

        let self = this;

        var items = self.view.find('.list-item input');
        if (!items.length) {
            return;
        }

        let url = self.view.data('save-url');
        if (isEmpty(url)) {
            console.warn("ListItems : couldn't found save-url for item '" + xpath(self.view[0]) + "'");
            return;
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: items.serialize(),
            success: function (data) {
                self.view.find('.list-items').append(data);
                self._toggleEmptyLine();

                toastr.success("Your changes have been successfully updated !");
            },
            error: function (xhr, ajaxOptions, thrownError) {

            }
        });
    }

    delete(e) {
        e.stopPropagation();

        let self = this;

        let $item = $(e.currentTarget);
        let $parent = $item.parents('.list-item');
        let index = $parent.index();

        var id = $('.list-item-id', $parent).val();
        if (!isDefault(id)) {
            self._consolidateList(index);
        }

        let url = self.view.data('delete-url');
        if (isEmpty(url)) {
            console.warn("ListItems : couldn't found delete-url for item '" + xpath(self.view[0]) + "'");
            return;
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: { id: $('.list-item-id', $parent).val() },
            success: function (data) {
                if (data.status) {
                    // Supprimé en base, on le retire de la view ici ?

                    toastr.success("This item has been successfully deleted !");
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {

            }
        });

        // On le retire de la view, indépendamment du retour ajax ?
        $parent.remove();

        self._toggleEmptyLine();
    }

    import(e) {
        e.stopPropagation();

        let self = this;

        let url = self.view.data('import-url');
        let formData = new FormData();

        var file = self.view.find('input[type=file]')[0].files[0];
        if (file === undefined) {
            toastr.warning("You must select a file");
            return;
        }

        formData.append("id", self.view.data('id'));
        formData.append("file", file, file.name);

        $(self.view).html(`<div class='loader'><div></div></div>`);

        $.ajax({
            type: 'POST',
            url: url,
            data: formData,
            //xhr: function () {
            //    var myXhr = $.ajaxSettings.xhr();
            //    if (myXhr.upload) {
            //        myXhr.upload.addEventListener('progress', that.progressHandling, false);
            //    }
            //    return myXhr;
            //},
            success: function (data) {
                if (data.type === "converter") {
                    toastr.success("Your data have been successfully imported ! Reload page ! ");
                    $(".loader").remove();
                }
                else {
                    self.view.find('.list-items').html(data);

                    toastr.success("Your data have been successfully imported !");

                    self.view.find('.badge-success').click();
                }
                
            },
            error: function (error) {
                toastr.error("Data import error contact the administrator !");
            },
            async: true,
            cache: false,
            contentType: false,
            processData: false
        });
    }

    export(e) {
        e.stopPropagation();

        let self = this;

        let url = self.view.data('export-url');

        $.ajax({
            url: url,
            type: 'POST',
            data: {
                id: self.view.data('id')
            },
            success: (data, status, xhr) => {
                window.location = '/files/download/?id=' + data.id
                    + '&name=' + data.name + '&mime=' + data.mime;
            }
        });
    }

    _consolidateList(index) {
        let self = this;

        self.view.find('.list-item').each(function (i, e) {
            let $e = $(e);
            let oldIndex = i;
            if (oldIndex > index) {
                $('input', e).each(function (i, v) {
                    $(v).attr('name', $(v).attr('name').replace(self.itemNames + '[' + oldIndex + ']', self.itemNames + '[' + (oldIndex - 1) + ']'));
                    $(v).attr('id', $(v).attr('id').replace(self.itemNames + '_' + oldIndex + '_', self.itemNames + '_' + (oldIndex - 1) + '_'));
                });
            }
        });
    }

    _toggleEmptyLine() {
        let self = this;

        let empty = `<div class="empty text-center small">Cette liste est vide</div>`;
        if (!self.view.find('.list-item').length) {
            self.view.find('.list-items').append(empty);
        } else {
            self.view.find('.empty').remove();
        }
    }

    _triggerUpload(selector) {
        let self = this;

        let input = self.view.find(selector)[0];
        if (input && document.createEvent) {
            var evt = document.createEvent("MouseEvents");
            evt.initEvent("click", true, false);
            input.dispatchEvent(evt);
        }
    }
}